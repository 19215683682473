
    var riot = require('riot')
    
riot.tag2('video-modal', '<modal name="ytmodal" onclose="{stopVid}" bssize="lg" if="{showvids}" class="vidmodal"> <div style="padding:56.3% 0 0 0;position:relative;"> <div id="ytframe"></div> </div> </modal> <modal name="vimeomodal" onclose="{stopVimeo}" bssize="lg"> <div style="padding:56.3% 0 0 0;position:relative;"> <div id="vimeoframe"></div> </div> </modal> <div if="{openInIframe}"> <div id="ytiframe"></div> </div>', '#ytframe, #vimeoframe, #vimeoframe iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }', '', function(opts) {
window.onYouTubeIframeAPIReady = (function(_this) {
  return function() {
    _this.youtubeReady = true;
    return _this.setup();
  };
})(this);

this.vids = [];

this.player = null;

this.vimeoplayer = null;

this.youtubeReady = false;

this.openInIframe = null;

this.editmode = false;

this.on('mount', (function(_this) {
  return function() {
    console.log("init vid modal");
    return _this.init();
  };
})(this));

this.init = (function(_this) {
  return function() {
    var firstScriptTag, scrtag, srctag;
    document.addEventListener('click', function(e) {
      var atag, attr, epaths, hasVidClass, ref, ref1, regexvim, regexyt, vidid;
      _this.openInIframe = _this.checkModal(e.target);
      _this.editmode = (ref = /editmode/.test(document.body.classList)) != null ? ref : {
        "true": false
      };
      if (e.target.tagName === "A") {
        atag = e.target;
      } else {
        atag = e.target.querySelector('a[href]');
      }
      hasVidClass = (ref1 = /video(-*)link/.test(e.target.classList)) != null ? ref1 : {
        "true": false
      };
      epaths = e.path.filter(function(v) {
        return /video(-?)link/.test(v.className);
      });
      if ((hasVidClass || epaths.length > 0 && atag) && !_this.editmode) {
        e.preventDefault();
        vidid = false;
        attr = atag.href || atag.parentNode.href || false;
        regexyt = new RegExp(/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/);
        regexvim = new RegExp(/vimeo.com/);
        if (attr && regexyt.test(attr)) {
          vidid = _this.youtube_parser(attr);
          return _this.loadvideo(vidid);
        } else if (attr && regexvim.test(attr)) {
          vidid = _this.vimeo_parser(attr);
          return _this.loadvimeo(attr, vidid);
        }
      }
    });
    if (!_this.editmode) {
      _this.showvids = true;
      scrtag = document.createElement('script');
      scrtag.src = "https://www.youtube.com/iframe_api";
      firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(scrtag, firstScriptTag);
      srctag = document.createElement('script');
      srctag.src = "https://player.vimeo.com/api/player.js";
      firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(srctag, firstScriptTag);
    }
    return _this.update();
  };
})(this);

this.checkModal = (function(_this) {
  return function(elem) {
    var allModals;
    allModals = document.querySelectorAll('.modal-body');
    [].map.call(allModals, function(mod) {
      if (mod.contains(elem)) {
        return true;
      }
    });
    return false;
  };
})(this);

this.youtube_parser = function(url) {
  var match, regExp;
  regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  match = url.match(regExp);
  if (match && match[7].length === 11) {
    return match[7];
  } else {
    return false;
  }
};

this.vimeo_parser = function(url) {
  var match, regExp;
  regExp = /http(s):\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  match = url.match(regExp);
  if (match && match[3].length) {
    return match[3];
  } else {
    return false;
  }
};

this.stopVid = (function(_this) {
  return function() {
    if (_this.player) {
      _this.player.destroy();
      _this.player = null;
    }
    if (_this.vimeoplayer) {
      _this.vimeoplayer.unload();
      _this.vimeoplayer = null;
    }
    return _this.update();
  };
})(this);

this.loadvimeo = (function(_this) {
  return function(url, id) {
    var vidopts;
    if (_this.vimeoplayer) {
      _this.vimeoplayer.destroy();
    }
    _this.vimeoplayer = null;
    if (_this.vimeoplayer === null) {
      vidopts = {
        id: url,
        width: '100%'
      };
      _this.vimeoplayer = new Vimeo.Player(_this.tags.vimeomodal.vimeoframe, vidopts);
      _this.update();
    } else if (_this.vimeoplayer) {
      _this.vimeoplayer.destroy();
      _this.vimeoplayer.loadVideo(id);
      _this.update();
    }
    _this.tags.vimeomodal.show();
    return setTimeout(function() {
      return _this.vimeoplayer.play();
    }, 500);
  };
})(this);

this.loadvideo = (function(_this) {
  return function(vidid) {
    _this.tags.ytmodal.show();
    _this.targetid = vidid;
    if (_this.openInIframe) {
      _this.setupIframe();
    } else {
      _this.setup();
    }
    return _this.update();
  };
})(this);

this.setup = (function(_this) {
  return function() {
    if (_this.player === null) {
      _this.player = new YT.Player(_this.tags.ytmodal.ytframe, {
        events: {
          'onReady': function() {
            return _this.onPlayerReady();
          },
          'onStateChange': _this.onPlayerStateChange,
          'onError': _this.vidError,
          'videoId': _this.targetid,
          'suggestedQuality': 'large'
        },
        playerVars: {
          'autoplay': 1,
          'rel': 0,
          'controls': 0,
          'showinfo': 0,
          'modestbranding': 1
        }
      });
    }
    return _this.update();
  };
})(this);

this.setupIframe = (function(_this) {
  return function() {
    if (_this.player === null) {
      _this.player = new YT.Player(docuemnt.querySelector('#ytiframe'), {
        events: {
          'onReady': function() {
            return _this.onPlayerReady();
          },
          'onStateChange': _this.onPlayerStateChange,
          'onError': _this.vidError,
          'videoId': _this.targetid,
          'suggestedQuality': 'large'
        },
        playerVars: {
          'autoplay': 1,
          'rel': 0,
          'controls': 0,
          'showinfo': 0,
          'modestbranding': 1
        }
      });
    }
    return _this.update();
  };
})(this);

this.vidError = (function(_this) {
  return function(e) {
    return console.log("error", e);
  };
})(this);

this.onPlayerReady = (function(_this) {
  return function() {
    var error;
    try {
      _this.player.cueVideoById({
        'videoId': _this.targetid,
        'suggestedQuality': 'large'
      });
      _this.player.playVideo();
    } catch (error1) {
      error = error1;
      console.log(error, _this.player);
      setTimeout(function() {
        _this.player.destroy();
        return _this.setup();
      }, 1000);
    }
    return _this.update();
  };
})(this);

this.onPlayerStateChange = (function(_this) {
  return function(e) {
    if (e.data === 0) {
      if (_this.player) {
        _this.player.destroy();
        _this.player = null;
      }
      _this.tags.ytmodal.close();
      return _this.update();
    }
  };
})(this);
});
    
  