
    var riot = require('riot')
    
riot.tag2('gmap-search-local', '<gmap-script></gmap-script>', '', '', function(opts) {
this.on('mount', function() {
  this.gmap = window.gmapscript;
  if ((typeof google !== "undefined" && google !== null ? google.maps : void 0) != null) {
    return this.load();
  } else if (this.gmap) {
    this.gmap.one('loaded', (function(_this) {
      return function() {
        return _this.load();
      };
    })(this));
    return this.gmap.trigger('loadscript');
  } else {
    throw new Error('Google Maps Api hasn\'t been loaded');
  }
});

this.load = (function(_this) {
  return function() {
    _this.gmap.trigger('linkmap', opts.linkedmap, function(map) {
      return _this.map = map;
    });
    _this.geocoder = new google.maps.Geocoder();
    if (opts.onupdate) {
      return opts.onupdate();
    }
  };
})(this);

this.getmarkers = (function(_this) {
  return function() {
    if ((typeof err !== "undefined" && err !== null) && (opts.onmarkersfound != null)) {
      opts.onmarkersfound({}, {}, {}, 'Postcode not found');
    }
    if (typeof err === "undefined" || err === null) {
      if (data.centre != null) {
        if (_this.marker) {
          _this.marker.setMap(null);
        }
        _this.marker = new google.maps.Marker({
          map: _this.map,
          position: new google.maps.LatLng(data.centre.latitude, data.centre.longitude),
          animation: google.maps.Animation.DROP,
          icon: '//www.google.com/mapfiles/arrow.png'
        });
        _this.update();
      }
      if (opts.onmarkersfound != null) {
        return opts.onmarkersfound(data.centre, data.markers, data.sponsored, err);
      }
    }
  };
})(this);

this.offsetMap = (function(_this) {
  return function(offsetx, offsety) {
    if (offsetx == null) {
      offsetx = 0;
    }
    if (offsety == null) {
      offsety = 0;
    }
    return setTimeout((function() {
      return _this.map.panBy(offsetx, offsety);
    }), 20);
  };
})(this);
});
    
  