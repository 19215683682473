
    var riot = require('riot')
    
riot.tag2('coursefilter', '<yield></yield>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/coursewords', true);

this.pageids = [];

this.chosensub = 0;

this.empty = false;

this.filtertext = "";

this.on('mount', (function(_this) {
  return function() {
    _this.dlist = document.querySelectorAll('.tcfilter');
    _this.headings = document.querySelectorAll('.course-grid-item h2');
    if (_this.dlist.length > 0) {
      [].map.call(_this.dlist, function(dl) {
        if (dl.getAttribute('data-id')) {
          return _this.pageids.push(parseInt(dl.getAttribute('data-id')));
        }
      });
      return api.get({
        pid: _this.pageids
      }, function(data) {
        _this.searchtext = data;
        return _this.searchtext.forEach(function(st) {
          if (st.page_id === 686) {
            return console.log(st);
          }
        });
      });
    }
  };
})(this));

this.subselect = (function(_this) {
  return function(id, txt) {
    if (txt == null) {
      txt = null;
    }
    _this.chosensub = 0;
    _this.chosensubtext = false;
    if (id === 0) {
      _this.chosensub = 0;
      _this.tags.dropdown.tags.subcoursetitle.title = "SUBJECT";
    } else if (id && typeof id === "number") {
      _this.chosensub = id;
      _this.chosensubtext = txt;
      _this.tags.dropdown.tags.subcoursetitle.title = _this.chosensubtext;
    }
    _this.button = document.querySelector('.submitbutton button');
    _this.button.classList.add('notify');
    return setTimeout(function() {
      return _this.button.classList.remove('notify');
    }, 2100);
  };
})(this);

this.filtercourses = (function(_this) {
  return function(e) {
    var hidden;
    e.preventDefault();
    _this.empty = true;
    if (_this.chosensub === 0) {
      [].map.call(_this.dlist, function(dl) {
        return dl.classList.remove('hideme');
      });
      [].map.call(_this.headings, function(heading) {
        return heading.classList.remove('hidetitle');
      });
    } else if (_this.chosensub > 0) {
      [].map.call(_this.dlist, function(dl) {
        var parent;
        dl.classList.add('hideme');
        parent = parseInt(_this.escapeRegExp(dl.getAttribute('data-pid')));
        if (parent === _this.chosensub) {
          return dl.classList.remove('hideme');
        }
      });
      [].map.call(_this.headings, function(heading) {
        var parent;
        heading.classList.add('hidetitle');
        parent = parseInt(_this.escapeRegExp(heading.getAttribute('data-pid')));
        if (parent === _this.chosensub) {
          return heading.classList.remove('hidetitle');
        }
      });
    }
    _this.wordsearch();
    if (_this.chosensub > 0 || _this.wordarray.length > 0) {
      _this.filtertext = "Filtering by: ";
      if (_this.chosensubtext) {
        _this.filtertext += '(subject) ' + _this.chosensubtext + '  ';
      }
      if (_this.wordarray.length > 0) {
        _this.filtertext += "(keywords) " + _this.wordarray.join(", ");
      }
      _this.tags.coursefiltertext.filtertext = _this.filtertext;
      _this.update();
    } else {
      _this.tags.coursefiltertext.filtertext = null;
      _this.update();
    }
    hidden = document.querySelectorAll('.hideme');
    if (hidden.length < _this.dlist.length) {
      _this.empty = false;
    }
    return _this.tags.noresults.empty = _this.empty;
  };
})(this);

this.wordsearch = (function(_this) {
  return function(e) {
    _this.wordresults = [];
    _this.wordarray = _this.searchwords.value.split(' ').filter(function(x) {
      return x.length > 0;
    });
    if (_this.wordarray.length > 0) {
      _this.searchtext.forEach(function(st) {
        return _this.wordarray.forEach(function(word) {
          var w;
          w = new RegExp(word, 'gi');
          if (st.words.search(w) > -1) {
            return _this.wordresults.push(parseInt(st.page_id));
          }
        });
      });
      [].map.call(_this.dlist, function(dl) {
        dl.classList.add('hideme');
        if (_this.wordresults.indexOf(parseInt(dl.getAttribute('data-id'))) > -1) {
          if ((_this.chosensub > 0 && _this.chosensub === parseInt(_this.escapeRegExp(dl.getAttribute('data-pid')))) || _this.chosensub === 0) {
            return dl.classList.remove('hideme');
          }
        }
      });
      return [].map.call(_this.headings, function(heading) {
        heading.classList.add('hidetitle');
        if (_this.wordresults.indexOf(parseInt(heading.getAttribute('data-id'))) > -1) {
          if ((_this.chosensub > 0 && _this.chosensub === parseInt(_this.escapeRegExp(heading.getAttribute('data-pid')))) || _this.chosensub === 0) {
            return heading.classList.remove('hidetitle');
          }
        }
      });
    } else {
      return [].map.call(_this.dlist, function(dl) {
        return dl.classList.remove('hidemeword', 'hidetitle');
      });
    }
  };
})(this);

this.escapeRegExp = (function(_this) {
  return function(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };
})(this);
});
riot.tag2('coursefiltertext', '<p class="text-right"><small if="{filtertext}">{filtertext}</small></p>', '', '', function(opts) {
});
riot.tag2('subcoursetitle', '<a href="#" role="button" onclick="{toggle}">{title}</a>', '', '', function(opts) {
this.title = "SUBJECT";
});
riot.tag2('noresults', '<h3 if="{empty}">Sorry, your search returned no results</h3>', '', '', function(opts) {
this.empty = false;
});
    
  