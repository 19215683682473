
    var riot = require('riot')
    
riot.tag2('categories-input', '<div class="current-categories"> <h4>Current Categories</h4> <p if="{currentCategories.length == 0}">None found</p> <div class="categories btn-toolbar"> <div each="{category in currentCategories}" onclick="{parent.removeCategory.bind(null,category)}" class="btn btn-primary btn-xs">{category.label} <i class="fa fa-times remove"></i></div> </div> </div> <div class="form"> <div class="form-group"> <h4>Add Category</h4> <div class="input-group"> <input name="add_label_input" class="form-control"> <div class="input-group-btn"> <button type="submit" onclick="{addCategory}" class="btn btn-primary">Add</button> </div> </div> </div> </div> <div class="all-categories"> <h4>All Categories</h4> <p if="{allCategories.length == 0}">None found</p> <div class="categories btn-toolbar"> <div each="{category in allCategories}" onclick="{parent.useCategory.bind(null, category)}" class="btn btn-primary btn-xs">{category.label} <i class="fa fa-plus add"></i></div> </div> </div>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/categories', true);

this.on('mount', function() {
  this.getAllCategories();
  return this.getCurrentCategories();
});

this.getAllCategories = (function(_this) {
  return function() {
    return api.get(function(data) {
      _this.allCategories = data;
      return _this.update();
    });
  };
})(this);

this.getCurrentCategories = (function(_this) {
  return function() {
    return api.get({
      id: opts.page_id
    }, function(data) {
      _this.currentCategories = data;
      return _this.update();
    });
  };
})(this);

this.useCategory = (function(_this) {
  return function(category) {
    var already, newCategory;
    already = (_this.currentCategories.filter(function(t) {
      return t.label === category.label;
    })).length;
    if (already > 0) {
      return;
    }
    newCategory = {
      page_id: opts.page_id,
      label: category.label
    };
    return api.save(newCategory, function(data) {
      _this.currentCategories.push(data);
      return _this.update();
    });
  };
})(this);

this.addCategory = (function(_this) {
  return function() {
    var newCategory;
    newCategory = {
      page_id: opts.page_id,
      label: _this.add_label_input.value
    };
    return api.save(newCategory, function(data) {
      _this.add_label_input.value = '';
      _this.currentCategories.push(data);
      _this.getAllCategories();
      return _this.update();
    });
  };
})(this);

this.removeCategory = (function(_this) {
  return function(category) {
    return api.remove({
      id: category.id
    }, function() {
      _this.currentCategories = _this.currentCategories.filter(function(t) {
        return category.id !== t.id;
      });
      _this.getAllCategories();
      return _this.update();
    });
  };
})(this);
});
    
  