
    var riot = require('riot')
    
riot.tag2('sitesearch', '<button onclick="{tags.popover.clicked}" class="sitesearch"><i class="fa fa-search"></i></button> <popover placement="left"> <form id="searchform" method="get" action="/system/search/" onsubmit="{parent.submitsearch}"> <div class="input-group form-group-sm"> <input placeholder="Search Website" name="q" value="" onkeyup="{parent.submitsearch}" class="form-control"> <div class="input-group-btn"> <button id="searchsubmit" type="submit" onclick="{parent.submitsearch}" class="btn btn-default btn-sm searchsubmit"><span class="fa fa-search searchsubmit"></span></button> </div> </div> </form> </popover>', 'popover .popover.left { background-color: #d60b52; } popover .popover.left .arrow:after { border-left-color: #d60b52; }', '', function(opts) {
this.submitsearch = (function(_this) {
  return function(e) {
    if (e.srcElement.classList.contains('searchsubmit') || e.keyCode === "13") {
      return _this.tags.popover.searchform.submit();
    }
  };
})(this);
});
    
  