
    var riot = require('riot')
    
riot.tag2('openlogin', '<yield></yield>', '', '', function(opts) {
this.on("mount", function() {
  return this.root.addEventListener("click", this.showlogin);
});

this.on("unmount", function() {
  return this.root.removeEventListener("click", this.showlogin);
});

this.showlogin = (function(_this) {
  return function(e) {
    return document.querySelector('loginbar')._tag.tags.loginmodal.show();
  };
})(this);
});
    
  