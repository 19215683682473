
    var riot = require('riot')
    
riot.tag2('signup', '<div class="profile"> <form name="profileform" onsubmit="{handleSaveProfile}" class="form-horizontal"> <div class="row"> <div class="col-xs-12"> <forminput name="email" label="Email" onupdate="{handleUpdate}" value="{user.email}" width1="3" width2="9" isrequired="{true}"></forminput> <forminput name="first_name" label="First Name" onupdate="{handleUpdate}" value="{user.first_name}" width1="3" width2="9" isrequired="{true}"></forminput> <forminput name="surname" label="Surname" onupdate="{handleUpdate}" value="{user.surname}" width1="3" width2="9" isrequired="{true}"></forminput> <forminput name="phone" label="Phone" onupdate="{handleUpdate}" value="{user.phone}" width1="3" width2="9"></forminput> <forminput name="job_title" label="Job Title" onupdate="{handleUpdate}" value="{user.job_title}" width1="3" width2="9"></forminput> <forminput name="organisation" label="Organisation" onupdate="{handleUpdate}" value="{user.organisation}" width1="3" width2="9" isrequired="{true}"></forminput> <formtextarea name="comments" label="Comments" onupdate="{handleUpdate}" value="{user.comments}" width1="3" width2="9"></formtextarea> <formselect name="accesstype" label="Access Type" onupdate="{handleUpdate}" value="{user.accesstype}" width1="3" width2="9" isrequired="{true}" options="{accesstypes}" default="Please Select"></formselect> <div class="row"> <div class="col-xs-9 col-xs-offset-3"> <button type="submit" class="btn btn-primary">Sign Up</button> </div> </div> <p><br></p> <div if="{message}" class="alert {\'alert-\'+status}">{message}</div> </div> </div> </form> </div>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.accesstypes = [
  {
    value: "full access",
    label: "Member Access ( Member organisations only )"
  }, {
    value: "forum access",
    label: "Discussion Forum"
  }
];

this.on("mount", function() {
  this.user = {};
  if (window.location.search.match(/editmode=/)) {
    return this.root.innerHTML = "<div style='border:2px dotted red;padding:10px;'>Signup form</div>";
  }
});

this.handleUpdate = (function(_this) {
  return function(update) {
    _this.user[update.name] = update.value;
    return _this.update();
  };
})(this);

this.handleSaveProfile = (function(_this) {
  return function(e) {
    e.preventDefault();
    return xhr("POST", "/duocms/api/tsa/register", _this.user, function(res) {
      var status;
      status = res.status === 200 ? "success" : "danger";
      _this.update({
        message: res.body.message || res.body.data.message,
        status: status
      });
      if (status === "success") {
        return _this.profileform.reset();
      }
    });
  };
})(this);
});
    
  