let isEditMode = window.location.search.match(/editmode=true/)

let showlogin = function(){
  let loginbar = document.querySelector('loginbar')
  if(loginbar && loginbar._tag){ 
    if(loginbar._tag.redir) loginbar._tag.redir = null 
    loginbar._tag.tags.loginmodal.show()
  }
}
  
// forum stuff
import {component,Forum,Toaster} from "duo-kit"
import ForumLatest from "./forum-latest.svelte"
import SettingsPanel from "./components/settings-panel.svelte"
component({component:Forum,tagname:"duo-forum",href:"/resources/css/site.css",shadow:isEditMode,attributes:["forumid"],defaults:{logintxt:"Login or Register",showlogin}})
component({component:Toaster,tagname:"duo-toaster",href:"/resources/css/site.css",shadow:isEditMode})
component({component:ForumLatest,tagname:"forum-latest",href:"/resources/css/site.css",shadow:isEditMode})

component({component:SettingsPanel,tagname:"settings-panel",href:"/resources/css/site.css",shadow:isEditMode})

var $, blogcheck, body, ctas, datalinks, imagesLoaded, jQuery, parallax, resizeAllGridItems, resizeGridItem, resizeInstance, riot;

window.riot = riot = require('riot');
require('riot-kit/lib/slideshow');
require('riot-kit/lib/img_loader');
require('riot-kit/lib/form');
require('riot-kit/lib/modal');
require('riot-kit/lib/form');
require('riot-kit/lib/ecommerce');
require('riot-kit/lib/pagination');
require('riot-kit/lib/dateformat');
require('riot-kit/lib/booking-calendar');
// require('riot-kit/lib/dropdown');
require('riot-kit/lib/gmap');
require('riot-kit/lib/auth/forgot-password');
require('riot-kit/lib/textresize');
// require('riot-kit/lib/social-share');
require('riot-kit/lib/raw');
imagesLoaded = require('imagesloaded');
require('./tags/twitter.tag');
require('./tags/teamadmin.tag');
require('./tags/loginbar.tag');
require('./tags/ecommercebar.tag');
require('./tags/navigation.tag');
require('./tags/mydetails.tag');
require('./tags/mypassword.tag');
require('./tags/signup.tag');
require('./tags/tags-input.tag');
require('./tags/comments_input.tag');
require('./tags/comments_output.tag');
require('./tags/recaptcha.tag');
require('./libs/slideshow.coffee');
require('./tags/faqs.tag');
require('./tags/searchmap.tag');
require('./tags/gmap-search-local');
require('./tags/uploadimage.tag');
require('./tags/openlogin.tag');
require('./tags/member-graphic.tag');
require('riot-kit/lib/popover');
require('./tags/sitesearch.tag');
require('./tags/gtranslate.tag');
require('./tags/coursefilter.tag');
require('./tags/categories-input.tag');

require('./tags/dropdown.tag');
require('./tags/videomodal.tag');
require('./tags/directorycard.tag');

require('./tags/social-share.tag');
require('./tags/modal.tag');

$ = jQuery = window.jQuery = window.$ = require("jquery");
require('owl.carousel');
riot.mount('*');
document.addEventListener('duocms:updated', function() {
  return riot.mount('slideshow,gmap');
});


var parallaxes = document.querySelectorAll('.parallax,.block-parallax');
// console.log(parallaxes);

window.checkscroll = () => {
  if(parallaxes){
    [].map.call(parallaxes,function (parallax){
      // console.log("ppp",parallax);
      var max, percent, pimg, prect, tx, wh;
      wh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      prect = parallax.getBoundingClientRect();
      max = wh + prect.height;
      percent = (prect.bottom / max) * 100;
      if (percent >= 0) {
        pimg = parallax.querySelector('.parallax-image') || parallax.querySelector('.figure-parallax-image');
        // console.log("img",pimg)
        tx = ((100 - percent) / 10) * 2;
        return pimg.style.transform = 'translate3d(0,-' + tx + '%,0)';
      }
    });
  }
}

// parallax = document.querySelector('.parallax');
// var parallax2 = document.querySelector('.block-parallax');

// if(parallax2 && !parallax){parallax = parallax2;}

// console.log("parallax",parallax);

// window.checkscroll = () => {
//   var max, percent, pimg, prect, tx, wh;
//   if (parallax) {
//     console.log(parallax);
//     wh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
//     prect = parallax.getBoundingClientRect();
//     max = wh + prect.height;
//     percent = (prect.bottom / max) * 100;
//     if (percent >= 0) {
//       pimg = parallax.querySelector('.parallax-image') || parallax.querySelector('.figure-parallax-image');
//       console.log("img",pimg)
//       tx = ((100 - percent) / 10) * 2;
//       return pimg.style.transform = 'translate3d(0,-' + tx + '%,0)';
//     }
//   }
// };

['scroll', 'load', 'resize'].forEach((type) => {
  window.addEventListener(type, checkscroll);
  if (type === "load" && parallaxes) {
    [].map.call(parallaxes,function (parallax){
      var img, ref, pbg;
      img = ((ref = parallax.querySelector('img')) != null ? ref.getAttribute('src') : void 0) || "";
      pbg = parallax.querySelector('.parallax-image') || parallax.querySelector('.figure-parallax-image');
      if (img && pbg) {
        return pbg.style.backgroundImage = "url('" + img + "')";
      }
    });
  }
});

// ['scroll', 'load', 'resize'].forEach((type) => {
//   var img, ref, pbg;
//   window.addEventListener(type, checkscroll);
//   if (type === "load" && parallax) {
//     img = ((ref = parallax.querySelector('img')) != null ? ref.getAttribute('src') : void 0) || "";
//     pbg = parallax.querySelector('.parallax-image') || parallax.querySelector('.figure-parallax-image');
//     if (img && pbg) {
//       return pbg.style.backgroundImage = "url('" + img + "')";
//     }
//   }
// });

setTimeout(() => {
  return $(document).ready(() => {
    return $('.dl_testimonials').owlCarousel({
      items: 1,
      pagination: true,
      nav: true,
      margin: 15,
      navContainer: '#artists-nav',
      autoplay: true,
      loop: true,
      autoplayTimeout: 8000
    });
  });
}, 1000);

body = document.querySelector('body');

ctas = document.querySelectorAll('.cta');

if (ctas.length && !body.classList.contains('editmode')) {
  [].forEach.call(ctas, function(cta) {
    var link;
    link = cta.querySelector('a[href]');
    if (link && link.getAttribute('href')) {
      return cta.addEventListener('click', function(e) {
        return window.location.href = link.getAttribute('href');
      });
    }
  });
}

datalinks = document.querySelectorAll('.datalink');

if (datalinks) {
  [].forEach.call(datalinks, function(dlink) {
    return dlink.addEventListener('click', function(e) {
      if (e.currentTarget.getAttribute('data-path')) {
        return window.location.href = e.currentTarget.getAttribute('data-path');
      }
    });
  });
}

resizeGridItem = function(item) {
  var grid, rowGap, rowHeight, rowSpan;
  grid = document.getElementsByClassName('dl_blog')[0];
  rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
  if (item.querySelector('.media')) {
    rowSpan = Math.ceil((item.querySelector('.media').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
    item.style.gridRowEnd = 'span ' + rowSpan;
  }
};

// item.classList.add('loaded')
resizeAllGridItems = function() {
  var allItems, x;
  allItems = document.getElementsByClassName('blogitem');
  x = 0;
  while (x < allItems.length) {
    resizeGridItem(allItems[x]);
    x++;
  }
};

resizeInstance = function(instance) {
  var item;
  item = instance.elements[0];
  resizeGridItem(item);
};

blogcheck = setInterval(() => {
  var allItems, blog, x;
  blog = document.querySelectorAll('.dl_blog');
  if (blog.length) {
    clearInterval(blogcheck);
    // window.onload = resizeAllGridItems()
    window.addEventListener('resize', resizeAllGridItems);
    allItems = document.getElementsByClassName('blogitem');
    x = 0;
    return setTimeout(() => {
      var results;
      results = [];
      while (x < allItems.length) {
        imagesLoaded(allItems[x], resizeInstance);
        x++;
        if (x === allItems.length) {
          results.push([].forEach.call(blog, (b) => {
            b.classList.add('loaded');
            return resizeAllGridItems();
          }));
        } else {
          results.push(void 0);
        }
      }
      return results;
    }, 1000);
  }
}, 100);

// var navitems = document.querySelectorAll('.navbar-nav a');
// console.log("ni",navitems.length);
// if(navitems){
//   [].forEach.call(navitems,function(navitem){
//     // console.log("navitem",navitem);
//     navitem.addEventListener('touchmove',function(e){
//       console.log("touchmove",e)
//     })
//   });
// }

// document.querySelectorAll('.navbar-nav > li > span').forEach((navItem) => {
//   navItem.addEventListener('click', function() {
//     document.querySelectorAll('.navbar-nav > li.open').forEach((sibling) => {
//       if (sibling !== this) {
//         sibling.classList.remove('open');
//       }
//     });
//     console.log(this.parentElement)
//     this.parentElement.classList.toggle('open');
//   });
// });

document.querySelectorAll('.navbar-nav > li > span').forEach((navItem) => {
  navItem.addEventListener('click', function() {
    document.querySelectorAll('.navbar-nav > li').forEach((sibling) => {
      if (sibling !== navItem.parentElement) {
        sibling.classList.remove('open');
      }
    });
    this.parentElement.classList.toggle('open');
  });
});

document.querySelectorAll('.navbar-nav .closebutton span').forEach((navcloser)=>{
  navcloser.addEventListener('click',function(){
    document.querySelectorAll('.navbar-nav > li').forEach((sibling) => {
      sibling.classList.remove('open');
    });
  });
});



function nestComments(res) {
  const map = {};
  res.forEach((item) => {
    map[item.id] = { ...item, children: [] };
  });

  const nested = [];
  res.forEach((item) => {
    if (item.parent_id) {
      map[item.parent_id].children.push(map[item.id]);
    } else {
      nested.push(map[item.id]);
    }
  });

  return nested;
}