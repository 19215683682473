<script>
  import {api,Timesince} from "duo-kit"
  export let path = "/-covid-19/discussion-forums/"
  let posts
  const getPosts = async ()=>{
    posts = (await api.load({url:"/duocms/api/forums/134/latest"})).data
  }
  const gotoPost = (post)=>{
    window.location.pathname= "#{path}?category=#{post.category.id}&postpage=0&post=#{post.id}"
  }
  getPosts()

</script>

<div class="block-primary">
  <div class="container">
    {#if posts}
      <div class="hidden-sm hidden-xs col-md-2 icon">
        <img src="/images/assets/hexicons/forum.svg" alt="forum icon"/>
      </div>
      <div class="col-xs-12 col-md-9 posts">
        {#each posts as post}
          <a class="row forumline" href={`${path}?category=${post.category_id}&postpage=0&post=${post.id}`}>
            <div class="col-xs-12 col-md-8">{post.title} | {post.category}</div>
            <div class="col-xs-12 col-md-4 text-right">{post.first_name} {post.surname} - <Timesince date={new Date(post.updated_at)} /> ago &gt; </div>
          </a>
        {/each}
      </div>
    {/if}
  </div>
</div>

<style>
  .forumline{
    padding:10px 0;
    border-bottom:1px solid white;
    cursor:pointer; 
    background-color:rgba(0,0,0,0);
    transition:0.3s background-color;
    color:white; 
    display:block;
  }

  .forumline:hover{
    background-color:rgba(0,0,0,0.1)
  }


  .forumline:last-child{
    border-bottom:0px;
  }

  .icon img{
    margin:20px;
  }
  .posts{
    padding:20px;
  }



</style>