
    var riot = require('riot')
    
riot.tag2('directorycard', '<yield></yield>', '@media (min-width: 1200px) { .modal-xl .modal-lg { width: 1140px; } } @media (min-width: 1330px) { .modal-xl .modal-lg { width: 1270px; } } @media (min-width: 992px) { .modal-xl .modal-body { padding: 30px; } }', '', function(opts) {
var orgApi, resource;

this.cid = opts.cid || null;

resource = require('riot-kit/utils/apiResource.coffee');

orgApi = new resource('/duocms/api/dir/org', true);

this.on('mount', (function(_this) {
  return function() {
    if (_this.root.querySelector('a.modalbtn')) {
      return _this.root.querySelector('a.modalbtn').addEventListener('click', function(e) {
        e.preventDefault();
        return orgApi.get({
          id: _this.cid
        }, function(data, err) {
          if (!data.org) {
            _this.tags['modal' + _this.cid].tags.directorypage.updateHtml('<h1>Member not found</h1>');
          } else {
            _this.tags['modal' + _this.cid].tags.directorypage.updateAll(data);
          }
          return _this.tags['modal' + _this.cid].show();
        });
      });
    }
  };
})(this));

this.stopyt = (function(_this) {
  return function() {
    var ytmodals;
    ytmodals = document.querySelectorAll('.vidmodal');
    if (ytmodals.length) {
      return ytmodals.forEach(function(ytmodal) {
        return ytmodal.remove();
      });
    }
  };
})(this);
});
riot.tag2('directorypage', '<div class="row"> <div class="col-xs-12 col-md-8"> <div class="visible-xs visible-sm"> <p if="{logo}" class="text-center"><img riot-src="{logo}/rs-200x100a.jpg" class="org_img"></p> <p if="{!logo}" class="text-center"> <svg viewbox="0 0 100 100" width="100%" class="building"> <use xlink:href="#building"></use> </svg> </p> </div> <div if="{cols[0] &amp;&amp; showzero}" riot-tag="raw" content="{cols[0]}"></div> <h1>{org.name}</h1> <p if="{org.description}"><strong>Description</strong><br>{org.description}</p> <hr> <div riot-tag="directoryvids"> <div riot-tag="raw" content="{htmlAlt}"></div> </div> </div> <div class="col-xs-12 col-md-4"> <div class="panel panel-default modalpanel"> <div class="panel-body"> <div class="hidden-xs hidden-sm"> <p if="{logo}" class="text-center"><img riot-src="{logo}/rs-200x100a.jpg" class="org_img"></p> <p if="{!logo}" class="text-center"> <svg viewbox="0 0 100 100" width="100%" class="building"> <use xlink:href="#building"></use> </svg> </p> </div> <p><strong>Address</strong><br><span class="modaladdress">{org.address.replace(/\\n+/g, \'\\n\')}</span></p> <p if="{org.website}"><strong>Website</strong><br><a href="{org.website}" target="_blank">{org.website}</a></p> <p if="{org.phone}"><strong>Telephone</strong><br>{org.phone}</p> <p if="{org.email}"><strong>Email</strong><br>{org.email}</p> <div if="{cols[21]}" riot-tag="raw" content="{cols[21]}"></div> </div> </div> </div> </div>', '.social-share { position: fixed; bottom: 0; height: 65px; line-height: 65px; } .social-share .social-share-link .social-share-button { padding: 0; } .modaladdress { white-space: pre; } svg.building { width: 100px; max-height: 100px; margin: 10px auto; display: block; fill: #888; }', '', function(opts) {
this.html = "";

this.htmlAlt = "";

this.org = null;

this.logo = null;

this.cols = [];

this.colsAlt = [];

this.showzero = false;

this.on('mount', (function(_this) {
  return function() {
    _this.logo = opts.logo;
    return _this.update();
  };
})(this));

this.updateHtml = (function(_this) {
  return function(html) {
    _this.html = html;
    _this.cols = _this.html.split('<!--PARTBREAK-->');
    _this.cols.forEach(function(col, i) {
      _this.colsAlt[i] = "";
      if (i > 0 && i !== 21) {
        return _this.colsAlt[i] = col;
      }
    });
    _this.htmlAlt = _this.colsAlt.join('<!--PARTBREAK-->');
    return _this.update();
  };
})(this);

this.updateAll = (function(_this) {
  return function(data) {
    var ref;
    if (data.page) {
      _this.updateHtml(data.page.html);
    } else {
      _this.updateHtml('<h1>' + data.org.name + '</h1>');
    }
    _this.org = data.org;
    _this.zero = _this.stripHTML(_this.cols[0]);
    _this.showzero = (ref = _this.zero === _this.org.name) != null ? ref : {
      "false": true
    };
    return _this.update();
  };
})(this);

this.stripHTML = (function(_this) {
  return function(html) {
    return html.replace(/&nbsp;/g, " ").replace(/&amp;/g, "&").replace(/<style[^>]*>.+?<\/style[^>]*>/ig, "").replace(/(<([^>]+)>)/ig, " ").replace(/ {2}/g, " ").trim();
  };
})(this);
});
    
  